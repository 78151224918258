import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { gsap } from 'gsap';
import cx from 'classnames';

import { cursorVid, cursorDrag, cursorInfo } from './cursor.module.css';
import { useMove } from '@use-gesture/react';

// SVG
import PlayIcon from '../../svg/play.svg';
import PauseIcon from '../../svg/pause.svg';

// Context
export const CursorContext = createContext({
  cursorActive: false,
  cursorType: 'default',
});

export const AnimatedCursor = ({ children }) => {
  // Ref
  const cursorRef = useRef(null);

  // Local State
  const [cursorPos, setCursorPos] = useState([0, 0]);

  // Global State
  const [cursorActive, setCursorActive] = useState(false);
  const [cursorType, setCursorType] = useState(null);

  // Cusor UseEffect
  useEffect(() => {
    gsap.to(cursorRef.current, {
      left: () => cursorPos[0],
      top: () => cursorPos[1],
      opacity: () => (cursorActive ? 1 : 0),
      scale: () => (cursorActive ? 1 : 0),
      ease: 'power2.out',
    });
  }, [cursorPos]);

  const vidValues = ['vid-play', 'vid-pause'];

  const bind = useMove(({ values }) => setCursorPos([values[0], values[1]]));

  return (
    <CursorContext.Provider
      value={{
        cursorActive,
        setCursorActive,
        cursorType,
        setCursorType,
      }}>
      <div className='cursor-wrapper' {...bind()}>
        <div
          className={cx(
            'rounded-full fixed top-0 left-0 pointer-events-none  z-[7000] items-center justify-center hidden lg:flex',
            {
              [cursorVid]: vidValues.includes(cursorType),
              [cursorDrag]: cursorType === 'drag',
              [cursorInfo]: cursorType === 'info',
            },
          )}
          ref={cursorRef}>
          {cursorType === 'vid-play' && (
            <PauseIcon className='w-4 fill-current text-white' />
          )}
          {cursorType === 'vid-pause' && (
            <PlayIcon className='w-4 fill-current text-white ' />
          )}
          {cursorType === 'drag' && <span>Drag</span>}
          {cursorType === 'info' && <span className=''>More Info</span>}
        </div>

        {children}
      </div>
    </CursorContext.Provider>
  );
};

export const useAppCursor = () => useContext(CursorContext);
