import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import loadable from '@loadable/component';
import { useInView } from 'react-intersection-observer';

import Image from 'gatsby-plugin-sanity-image';

import ArrowUp from '../../svg/arrow-up.svg';

import { useSiteConfig } from '../../GraphQl/useSiteConfig';

import { PortableTextBlock } from '../sanity/portableTextBlock';

const BackgroundVideo = loadable(() => import('../media/backgroundVideo'));

const isClient = typeof window === 'object';

export const Footer = () => {
  // Refs
  const headline = useRef(null);
  const button = useRef(null);
  const social = useRef(null);
  const details = useRef(null);
  const detailsWrapper = useRef(null);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });

  const { footerImage, contactDetails, socialHandles, footerVideo } =
    useSiteConfig();

  useEffect(() => {
    gsap.from(
      [details.current, social.current, headline.current, button.current],
      {
        duration: 0.8,
        opacity: 0,
        xPercent: -20,
        stagger: 0.1,
        scrollTrigger: {
          trigger: detailsWrapper.current,
          start: 'top center+=100',
          toggleActions: 'play none none reverse',
        },
      },
    );
  }, []);

  const scrollTop = () => {
    if (!isClient) {
      return false;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer
      className='grid grid-cols-14 overflow-hidden lg:min-h-screen'
      ref={ref}
      id='contact'>
      {/* Icon */}

      {/* Contact Details */}
      <div className='col-start-2 col-end-10 z-10 row-start-2 row-end-3 relative self-end pb-16 pt-16 lg:row-end-4'>
        <h2 className='text-4xl font-display lg:text-7xl mb-10' ref={headline}>
          Get in touch
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2' ref={details}>
          {contactDetails.map(({ _rawText }) => (
            <PortableTextBlock
              text={_rawText}
              className='prose prose-lg text-white font-display'
            />
          ))}
        </div>
      </div>
      {/* Scroll to top Link */}
      <div
        className='col-start-13 col-end-14 justify-self-end pt-32 row-start-1 z-10 relative'
        ref={detailsWrapper}>
        <button
          className='text-white flex flex-col items-center outline-none focus:outline-none'
          onClick={scrollTop}
          ref={button}>
          <ArrowUp className='w-4 fill-current mb-4' />
          <span className='vert-text uppercase text-md font-medium tracking-wider'>
            Back to top
          </span>
        </button>
      </div>
      {/* Social Handles  */}
      <div className='col-start-2 col-end-14 row-start-4 pb-16 relative z-10 lg:col-start-13 lg:row-start-3 lg:self-end'>
        <div className='grid gap-y-2 text-left lg:text-right' ref={social}>
          {socialHandles.map(({ link, linkText }) => (
            <a href={link} target='_blank' className='underline text-lg'>
              {linkText}
            </a>
          ))}
        </div>
      </div>
      {/* Image  */}
      <div
        className='col-start-1 col-end-15 relative row-start-1 row-end-5 lg:col-end-12 lg:row-end-4 overflow-hidden bg-placeholder'
        style={{ background: '#181818' }}>
        <div className='w-full h-full absolute top-0 left-0 lg:aspect-w-8 lg:aspect-h-5 lg:relative bg-black'>
          {footerVideo && inView && (
            <BackgroundVideo
              video={footerVideo}
              className='h-full w-full opacity-40 top-0 left-0'
            />
          )}
        </div>
      </div>
    </footer>
  );
};
