import React from 'react';

import LogoMark from '../../svg/hyper-icon.svg';
import LogoFull from '../../svg/logo-full.svg';

export const SiteLogo = ({ isScrolled, navOpen }) => {
  return (
    <a
      href='/'
      className={`w-24 h-16 relative grid grid-cols-1 grid-rows-1 transition-opacity duration-700 ${
        navOpen ? 'opacity-0' : 'opacity-100 delay-1000'
      }`}>
      <div
        className={`w-10 fill-current transition-opacity duration-1000 row-start-1 self-center ${
          isScrolled ? 'opacity-100 delay-700' : 'opacity-0'
        } md:w-12`}>
        <LogoMark />
      </div>
      <div
        className={`w-24 fill-current transition-opacity row-start-1 self-center duration-1000 ${
          isScrolled ? 'opacity-0' : 'opacity-100 delay-700'
        } md:w-32`}>
        <LogoFull />
      </div>
    </a>
  );
};
