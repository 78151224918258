import React from 'react';

const DesktopStickyServicesNav = ({ nodes }) => {
  return (
    <div className='mb-20'>
      <div className='rounded-full text-sm 2xl:text-base flex 2xl:gap-x-4 backdrop-blur-md bg-white/30 pointer-events-auto'>
        {nodes.map((service) => {
          const { title, pageLink } = service;
          return (
            <a
              className='cursor-pointer rounded-full hover:bg-[#F5F5F5] hover:text-black p-4 transition-all ease-linear duration-300'
              href={`/${pageLink.slug.current}`}>
              {title}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default DesktopStickyServicesNav;
