import React from 'react';
import PortableText from 'react-portable-text';

// Block  Components
import {
  AnchorScrollLink,
  ExternalLink,
  PageAnchorLink,
  PageLink,
  PathLink,
} from '../ui/links';
import { ImageModule, VideoModule } from '../modules';

export const serializers = {
  h1: (props) => (
    <h1 {...props} className='text-[58px] md:text-[65px] font-display' />
  ),
  h2: (props) => (
    <h2 {...props} className='text-[38px] md:text-[58px] font-display' />
  ),
  h3: (props) => (
    <h3 {...props} className='text-[30px] md:text-[48px] font-display' />
  ),
  h4: (props) => (
    <h4 {...props} className='text-[24px] md:text-[38px] font-display' />
  ),
  h5: (props) => (
    <h5 {...props} className='text-[24px] md:text-[30px] font-display' />
  ),
  h6: (props) => (
    <h6 {...props} className='text-[18px] md:text-[20px] font-light' />
  ),
  'super-script': (props) => <sup>{props.children}</sup>,
  'sub-script': (props) => <sub>{props.children}</sub>,
  break: (props) => <span {...props} className='h-[10px] block' />,
  pageLink: (props) => <PageLink {...props} className='underline' />,
  pathLink: (props) => <PathLink {...props} className='underline' />,
  anchorLink: (props) => <AnchorScrollLink {...props} className='underline' />,
  externalLink: (props) => <ExternalLink {...props} className='underline' />,
  pageAnchorLink: (props) => (
    <PageAnchorLink {...props} className='underline' />
  ),
  normal: (props) => (
    <p
      {...props}
      className={`text-[18px] leading-normal font-display, ${props.className}`}
    />
  ),
  imageModule: (props) => <ImageModule {...props} className='py-10' />,
  videoModule: (props) => <VideoModule {...props} />,
  highlight: (props) => (
    <span {...props} className='text-accent-red'>
      {props.children}
    </span>
  ),
}; // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
  return <PortableText content={text} serializers={serializers} {...props} />;
};
