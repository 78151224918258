import React, { useEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';

import { useSiteConfig } from '../../GraphQl/useSiteConfig';

import HyperIcon from '../../svg/hyper-icon.svg';
import { NavLink } from './navLink';
import { PortableTextBlock } from '../sanity/portableTextBlock';

export const NavDraw = ({ isOpen, closeNav }) => {
  const { mainNav, contactDetails } = useSiteConfig();
  const [menuTl] = useState(gsap.timeline({ paused: true }));

  // Refs
  const bgLeft = useRef(null);
  const bgRight = useRef(null);
  const details = useRef(null);
  const linkRefs = useRef([]);

  useEffect(() => {
    menuTl
      .from([bgRight.current, bgLeft.current], {
        yPercent: -100,
        stagger: 0.2,
      })
      .from(linkRefs.current, { yPercent: 100, stagger: 0.2 }, 0.5)
      .from(details.current, { opacity: 0 }, 1)
      .reverse();
  }, []);

  useEffect(() => {
    menuTl.reversed(!isOpen);
    if (isOpen) {
      document.documentElement.classList.add('navOpen');
    } else {
      document.documentElement.classList.remove('navOpen');
    }
  }, [isOpen]);

  const addToNavRefs = (el) => {
    if (el && !linkRefs.current.includes(el)) {
      linkRefs.current.push(el);
    }
  };

  return (
    <div
      className={`h-screen w-full fixed top-0 left-0 text-black z-[4000] px-gutter flex items-center ${
        isOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }`}>
      {/* Left BG */}
      <div
        className='w-full md:w-1/2 h-screen bg-white absolute top-0 left-0 z-0'
        ref={bgLeft}
      />
      {/* Right BG */}
      <div
        className='hidden w-1/2 h-screen bg-white absolute top-0 right-0 z-0 md:block'
        ref={bgRight}
      />
      <div className='grid gap-x-36 self-center w-full'>
        {/* Nav Items */}
        <div className='row-start-1 grid gap-y-4 self-center relative z-10 md:gap-y-10'>
          {mainNav.map((item) => (
            <div className='overflow-hidden h-[110%]'>
              <div ref={addToNavRefs} onClick={closeNav}>
                <NavLink
                  {...item}
                  className='font-display text-3xl md:text-6xl'
                />
              </div>
            </div>
          ))}
        </div>
        {/* Contact Details */}
        <div
          className='hidden md:grid col-start-1 col-end-3 row-start-1 self-end  gap-y-10 z-10 justify-center'
          ref={details}>
          <div className='absolute top-9 left-gutter z-20'>
            <HyperIcon className='w-20' />
          </div>
          {contactDetails.map(({ _rawText }) => (
            <PortableTextBlock text={_rawText} className='prose prose-lg' />
          ))}
        </div>
      </div>
    </div>
  );
};
