import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import cx from 'classnames';

import ArrowUpAlt from '../../svg/arrow-up-alt.svg';

const MobileStickyServicesNav = ({ nodes }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const listRef = useRef();

  useEffect(() => {
    const listEl = listRef.current;

    gsap.to(listEl, {
      height: isExpanded ? 'auto' : listEl.firstChild.clientHeight,
      duration: 0.3,
    });
  }, [isExpanded]);

  const handleButtonClick = () => setIsExpanded((prev) => !prev);

  return (
    <div
      className={cx(
        'bg-black px-gutter text-white border-t border-white/50 pointer-events-auto w-full transition-[padding] duration-300',
        {
          'py-9': isExpanded,
          'py-4': !isExpanded,
        },
      )}>
      <ul
        className='space-y-7 font-display text-xl overflow-hidden'
        ref={listRef}>
        {nodes.map((node, index) => {
          const { title, pageLink } = node;
          const isFirst = index === 0;

          return (
            <li className={isFirst && 'flex justify-between items-center'}>
              <a href={pageLink.slug.current}>{title}</a>

              {isFirst && (
                <button
                  className='focus:outline-none'
                  onClick={handleButtonClick}>
                  <div
                    className={cx('transition-transform duration-300', {
                      'rotate-180': isExpanded,
                    })}>
                    <ArrowUpAlt />
                  </div>
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MobileStickyServicesNav;
