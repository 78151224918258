import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

// Animation
const top = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: 45,
    translateY: 2,
  },
};

const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: -45,
    translateY: -2,
  },
};

export const MenuToggle = ({
  isOpen,
  reverseHeader,
  width = 30,
  height = 15,
  ...props
}) => {
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;
  const topLine = useRef(null);
  const bottomLine = useRef(null);

  useEffect(() => {
    gsap.to(topLine.current, {
      rotate: () => (isOpen ? 45 : 0),
      translateY: () => (isOpen ? 2 : 0),
      transformOrigin: 'center center',
      ease: 'power2.out',
    });
    gsap.to(bottomLine.current, {
      rotate: () => (isOpen ? -45 : 0),
      translateY: () => (isOpen ? -2 : 0),
      transformOrigin: 'center center',
      ease: 'power2.out',
    });
  }, [isOpen]);

  return (
    <div {...props}>
      <svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow='visible'
        preserveAspectRatio='none'
        width={width}
        height={height}
        strokeWidth='2.5'
        className='stroke-current'>
        <line
          x1='0'
          x2={unitWidth}
          y1='0'
          y2='0'
          initial='closed'
          vectorEffect='non-scaling-stroke'
          ref={topLine}
        />
        <line
          x1='0'
          x2={unitWidth}
          y1='4'
          y2='4'
          initial='closed'
          vectorEffect='non-scaling-stroke'
          ref={bottomLine}
        />
      </svg>
    </div>
  );
};
