import React from 'react';

import AnchorScroll from 'react-anchor-link-smooth-scroll';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';

// Hooks & Querys
import { useSanityPath } from '../../hooks';

const isClient = typeof window === 'object';

export const PageLink = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page);
  return (
    <Link to={`/${page?.slug?.current}`} {...rest}>
      {linkText}
    </Link>
  );
};

export const PathLink = ({ path, linkText, ...rest }) => {
  return (
    <Link to={`/${path}`} {...rest}>
      {linkText}
    </Link>
  );
};

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page);

  return (
    <AnchorLink
      to={
        page._type === 'homePage'
          ? `/#${anchor?.current}`
          : `/${path}#${anchor?.current}`
      }
      {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ anchor, linkText, children, ...rest }) => {
  return (
    <AnchorScroll href={`#${anchor && anchor.current}`} {...rest}>
      {children || linkText}
    </AnchorScroll>
  );
};

export const GScrollLink = ({ anchor, linkText, ...rest }) => {
  const scrollTo = () => {
    window.bodyScrollBar.scrollIntoView(
      document.querySelector(`#${anchor && anchor.current}`),
      {
        onlyScrollIfNeeded: true,
      },
    );
  };

  return (
    <button {...rest} onClick={scrollTo}>
      {linkText}
    </button>
  );
};

export const ExternalLink = ({ linkText, link, ...rest }) => {
  return (
    <a href={link} target='_blank' rel='noreferrer'>
      {linkText}
    </a>
  );
};
