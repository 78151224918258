import React, { useState, useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { NavDraw } from './navDraw';

import { SiteLogo } from './siteLogo';
import { MenuToggle } from './menuToggle';

export const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const headerRef = useRef(null);
  const toggleNav = () => setNavOpen((prev) => !prev);
  const closeNav = () => setNavOpen(false);

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.create({
        start: 100,
        end: 'bottom bottom',
        onEnter: () => setScrolled(true),
        onLeaveBack: () => setScrolled(false),
        onLeave: () => setScrolled(false),
        onEnterBack: ({ direction }) => {
          if (direction === -1) {
            setScrolled(true);
          }
        },
      });
    }, 1000);
  }, []);

  return (
    <>
      <header
        className={`siteHeader ${scrolled ? 'is-scrolled' : ''} ${
          navOpen ? 'nav-open' : 'nav-closed'
        }`}
        ref={headerRef}>
        <div className='inner'>
          <SiteLogo isScrolled={scrolled} navOpen={navOpen} />
          <div onClick={toggleNav} className='cursor-pointer'>
            <MenuToggle isOpen={navOpen} />
          </div>
        </div>
      </header>
      <NavDraw isOpen={navOpen} closeNav={closeNav} />
    </>
  );
};
