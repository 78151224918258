import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useServiceQuery = () => {
  const { allSanityService } = useStaticQuery(graphql`
    query {
      allSanityService(sort: { fields: orderRank, order: ASC }) {
        nodes {
          title
          pageLink {
            slug {
              current
            }
          }
        }
      }
    }
  `);
  return allSanityService || {};
};
