import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react';
import { gsap } from 'gsap';
import Scroll from './ascroll';
import { useLocation } from '@reach/router';
// import { useServiceQuery } from "../GraphQl/useServic eQuery";
import '../css/main.css';

import StickyServicesNav from './stickyServicesNav/stickyServicesNav';
import HyperIcon from '../svg/hyper-icon.svg';

import { Footer } from './nav/footer';
import { Header } from './nav/header';
import { AnimatedCursor } from '../components/animation/animatedCursor';

const ScrollContext = createContext({
  scrollDefined: false,
});

const isClient = typeof window !== `undefined`;

const Layout = ({ children }) => {
  const { hash } = useLocation();

  // Refs
  const scrollerRef = useRef();
  const loaderRef = useRef();
  const loaderIcon = useRef();
  const iconMask = useRef();

  //  State
  const [scrollDefined, setScrollDefined] = useState(false);

  const initScroll = () => {
    Scroll(scrollerRef);
    setScrollDefined(true);
  };

  useEffect(() => {
    initScroll();

    gsap.set(iconMask.current, { visibility: 'visible' });

    const loaderIn = gsap.timeline({
      id: 'loaderIn',
      defaults: {
        duration: 1,
        ease: 'power1.out',
      },
    });
    const loaderOut = gsap.timeline({
      id: 'loaderOut',
      defaults: {
        duration: 0.9,
        delay: 1.2,
        ease: 'power2.out',
      },
    });

    loaderIn.from(loaderIcon.current, {
      yPercent: 200,
      opacity: 0,
      transformOrigin: 'top',
    });

    loaderOut.to([iconMask.current, loaderRef.current], {
      yPercent: -150,
      stagger: 0.2,
    });
  }, []);

  useEffect(() => {
    if (hash && isClient && scrollDefined) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 3000);
    }
  }, [hash, isClient, scrollDefined]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js.hs-scripts.com/2990158.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AnimatedCursor>
      {scrollDefined && <Header />}

      <div
        className='h-screen w-screen fixed top-0 left-0 z-[70000] bg-black text-white items-center justify-center flex'
        ref={loaderRef}>
        <div
          className='w-32 overflow-hidden'
          ref={iconMask}
          style={{ visibility: 'hidden' }}>
          <div ref={loaderIcon}>
            <HyperIcon className='fill-current' />
          </div>
        </div>
      </div>
      {/* Content */}
      <ScrollContext.Provider value={{ scrollDefined }}>
        <div id='viewport' ref={scrollerRef}>
          <div>
            <main>
              {children}
              <Footer />
            </main>
          </div>
        </div>
      </ScrollContext.Provider>
    </AnimatedCursor>
  );
};

export const useScrollContext = () => useContext(ScrollContext);

export default Layout;
