import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        horzArrow {
          asset {
            url
          }
        }
        vertArrow {
          asset {
            url
          }
        }
        mainNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPageAnchorLink {
            _key
            _type
            anchor {
              current
            }
            linkText
            page {
              ... on SanityPage {
                id
                _type
                slug {
                  current
                }
              }
              ... on SanityHomePage {
                id
                _type
              }
            }
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityAnchorLink {
            _type
            linkText
            anchor {
              current
            }
          }
        }
        footerImage {
          ...ImageWithPreview
        }
        footerVideo
        socialHandles {
          link
          linkText
        }
        contactDetails {
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);
  return sanitySiteConfig || {};
};
