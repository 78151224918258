import ASScroll from '@ashthornton/asscroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import Scrollbar from 'smooth-scrollbar';

const Scroll = (scrollerRef) => {
  gsap.registerPlugin(ScrollTrigger);

  const scroller = scrollerRef.current;

  const asscroll = new ASScroll({
    containerElement: scroller,
    disableRaf: true,
    disableNativeScrollbar: true,
  });

  window.aScrollPos = 0;

  gsap.ticker.add(asscroll.update);

  ScrollTrigger.defaults({
    scroller: asscroll.containerElement,
  });

  ScrollTrigger.scrollerProxy(asscroll.containerElement, {
    scrollTop(value) {
      return arguments.length
        ? (asscroll.currentPos = value)
        : asscroll.currentPos;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
  });

  asscroll.on('update', ScrollTrigger.update);
  asscroll.on('scrollEnd', (scrollPos) => (window.aScrollPos = scrollPos));

  ScrollTrigger.addEventListener('refresh', asscroll.resize);

  setTimeout(() => {
    asscroll.enable();
  }, 1000);
};

export default Scroll;
