import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from '@reach/router';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';

import { useServiceQuery } from '../../GraphQl/useServiceQuery';
import DesktopStickyServicesNav from './desktop';
import MobileStickyServicesNav from './mobile';

const StickyServicesNav = () => {
  const [isVisible, setIsVisible] = useState(true);

  const location = useLocation();
  const breakpoints = useBreakpoint();

  const { nodes } = useServiceQuery();
  const filteredNodes = nodes.filter((node) => {
    const { title, pageLink } = node;
    if (
      !title ||
      !pageLink?.slug?.current ||
      pageLink.slug.current === location.pathname.slice(1)
    )
      return false;

    return true;
  });

  const navRef = useRef();
  const viewportRef = useRef();

  useEffect(() => {
    viewportRef.current = document.getElementById('viewport');

    const contactEl = document.getElementById('contact');
    if (!contactEl) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => setIsVisible(!entry.isIntersecting));
      },
      { threshold: 0 },
    );

    observer.observe(contactEl);
  }, []);

  if (!filteredNodes?.length || !viewportRef.current) return null;

  return ReactDOM.createPortal(
    <div
      className={cx(
        'fixed bottom-0 inset-x-0 z-50 transition-[visibility,opacity] flex justify-center duration-300 pointer-events-none',
        { 'invisible opacity-0': !isVisible },
      )}
      ref={navRef}>
      {breakpoints.md ? (
        <MobileStickyServicesNav nodes={filteredNodes} />
      ) : (
        <DesktopStickyServicesNav nodes={filteredNodes} />
      )}
    </div>,
    viewportRef.current,
  );
};

export default StickyServicesNav;
